<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="modal-form-roles-permiso-export"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              Generar Listado Permisos y Roles
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-pane fade active show" id="myTabContent">
              <div class="tab-pane" id="Filtros">
                <div class="card-body">
                  <div class="form-group row">
                    <label for="roles" class="col-md-5">Roles</label>
                    <select
                      id="roles"
                      v-model="form.roles"
                      required
                      class="custom-select"
                      :class="$v.form.roles.$invalid ? 'is-invalid' : 'is-valid'"
                    >
                      <option
                        v-for="role in rolesPage"
                        v-bind:key="role.id"
                        v-bind:value="role.id"
                      >
                        {{ role.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                v-show="!$v.form.$invalid"
                @click="generarListadoExcel()"
                v-if="$store.getters.can('admin.permisos.export')"
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";
import Loading from "../../../../components/Loading";
import { required } from "vuelidate/lib/validators";
//import vSelect from "vue-select";
export default {
  name: "PermisosyRolesExport", //llegada tambien
  components: { Loading },
  data() {
    return {
      cargando:false,
      form: {
        name:true,
        roles: null,
      },
      filtros: {
        roles: null,
      },
      rolesPage: [],
    };
  },
  validations() {
    return {
        form: {
          roles: {
            required,
          },
        },
      };
  },
  beforeMount() {
    this.actGetRoles().then(() => {
      this.rolesPage = this.roles;
    });
  },
  computed: {
    ...mapState("modSeguridad", ["roles"]),
  },

  methods: {
    ...mapActions("modSeguridad", ["actGetRoles"]),
    generarListadoExcel() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/admin/permisos/export",
        data: { form: this.form, filtros: this.filtros },
      })
        .then((response) => {
          this.cargando = false;
          let data = response.data;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },

  async mounted() {
    this.cargando = true;
    this.cargando = false;
  },
};
</script>
