<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Administración de roles y permisos</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/Admin">Administrativo</router-link>
                  </li>
                  <li class="breadcrumb-item active">Seguridad</li>
                  <li class="breadcrumb-item active">Permisos</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-1 text-right">Rol</div>
                  <div class="col-md-6">
                    <select
                      v-model="role"
                      required
                      class="custom-select"
                      @change="traerPermisos()"
                    >
                      <option
                        v-for="role in rolesPage"
                        v-bind:key="role.id"
                        v-bind:value="role.id"
                      >
                        {{ role.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-2">
                    <div
                      v-if="loading"
                      class="spinner-border text-dark"
                      role="status"
                    >
                      <span class="sr-only">Guardando...</span>
                    </div>
                  </div>
                  <div class="btn-group float-right">
                    <button
                      type="button"
                      class="btn btn-info"
                      @click="pivotTable()"
                      v-if="$store.getters.can('admin.permisos.pivotTable')"
                    >
                      <i class="fas fa-scroll"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-success"
                      data-toggle="modal"
                      data-target="#modal-form-roles-permiso-export"
                      v-if="$store.getters.can('admin.permisos.export')"
                    >
                      <i class="far fa-file-excel"></i>
                    </button>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="row">
                <div class="col-12">
                  <ul
                    class="nav nav-pills nav-sidebar flex-column nav-flat nav-child-indent"
                    data-widget="treeview"
                    role="menu"
                    data-accordion="false"
                  >
                    <li
                      class="nav-item has-treeview"
                      v-for="base in this.permisosLocal"
                      :key="base.linea_negocio_id"
                    >
                      <a href="#" class="nav-link">
                        <i class="nav-icon fas fa-circle"></i>
                        <p>
                          {{ base.linea_negocio_name }}
                          <i class="right fas fa-angle-left"></i>
                        </p>
                      </a>
                      <ul class="nav nav-treeview">
                        <li
                          class="nav-item has-treeview"
                          v-for="menu in base.data[0]"
                          :key="menu.id"
                        >
                          <a href="#" class="nav-link">
                            <i class="nav-icon fas fa-circle"></i>
                            <p>
                              {{ menu.descripcion }}
                              <i class="right fas fa-angle-left"></i>
                            </p>
                          </a>
                          <ul
                            class="nav nav-treeview"
                            v-if="menu.nivel2.length > 0"
                          >
                            <li
                              class="nav-item"
                              v-for="nivel2 in menu.nivel2"
                              :key="nivel2.id"
                            >
                              <a href="#" class="nav-link">
                                <i class="nav-icon fas fa-circle"></i>
                                <p>
                                  {{ nivel2.descripcion }}
                                  <i class="right fas fa-angle-left"></i>
                                </p>
                              </a>
                              <ul
                                class="nav nav-treeview"
                                v-if="nivel2.nivel3.length > 0"
                              >
                                <li
                                  class="nav-item"
                                  v-for="nivel3 in nivel2.nivel3"
                                  :key="nivel3.id"
                                >
                                  <a href="#" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>{{ nivel3.descripcion }}</p>
                                    <i class="right fas fa-angle-left"></i>
                                  </a>
                                  <ul
                                    class="nav nav-treeview"
                                    v-if="nivel3.permisos.length > 0"
                                  >
                                    <div class="row">
                                      <div class="col-1"></div>
                                      <li
                                        class="nav-item"
                                        v-for="permiso in nivel3.permisos"
                                        :key="permiso.id"
                                      >
                                        <div class="col-12">
                                          <div
                                            class="custom-control custom-checkbox"
                                          >
                                            <input
                                              type="checkbox"
                                              :id="permiso.id"
                                              v-model="
                                                permisosGuardar[
                                                  nivel3.id + '-' + permiso.id
                                                ]
                                              "
                                              class="custom-control-input"
                                            />
                                            <label
                                              class="custom-control-label"
                                              :for="permiso.id"
                                              >{{ permiso.name }}</label
                                            >
                                          </div>
                                        </div>
                                      </li>
                                      <div
                                        v-show="loading == false"
                                        class="col-2"
                                      >
                                        <button
                                          type="button"
                                          v-on:click="guardaPermisos(nivel3.id)"
                                          class="btn btn-primary btn-sm"
                                        >
                                          Guardar
                                        </button>
                                      </div>
                                    </div>
                                  </ul>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!--UsuarioEstado ref="UsuarioEstado" /-->
      </div>
    </div>
    <PermisosyRolesExport ref="PermisosyRolesExport" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import serverConfig from "./../../../../services/config";
import PermisosyRolesExport from "./PermisosyRolesExport";

export default {
  name: "AdminRoles",
  components: {
    //pagination,
    PermisosyRolesExport,
  },
  data() {
    return {
      filtros: {
        n_document: null,
        name: null,
        email: null,
        status: null,
      },
      usuarios: {},
      listasForms: {
        estados: [],
      },
      rolesPage: [],
      role: "",
      permisosLocal: {},
      permisosGuardar: {},
    };
  },
  beforeMount() {
    this.actGetRoles().then(() => {
      this.rolesPage = this.roles;
    });
  },

  computed: {
    ...mapState("modSeguridad", ["roles", "permisos", "loading"]),
  },

  methods: {
    ...mapActions("modSeguridad", [
      "actGetRoles",
      "actGetPermisos",
      "actSavePermisos",
    ]),

    traerPermisos() {
      this.permisosLocal = {};
      this.actGetPermisos(this.role).then(() => {
        this.permisosLocal = this.permisos;

        for (let h = 0; h < this.permisosLocal.length; h++) {
          for (let i = 0; i < this.permisosLocal[h].data[0].length; i++) {
            for (
              let j = 0;
              j < this.permisosLocal[h].data[0][i].nivel2.length;
              j++
            ) {
              for (
                let l = 0;
                l < this.permisosLocal[h].data[0][i].nivel2[j].nivel3.length;
                l++
              ) {
                for (
                  let m = 0;
                  m <
                  this.permisosLocal[h].data[0][i].nivel2[j].nivel3[l].permisos
                    .length;
                  m++
                ) {
                  this.permisosGuardar[
                    this.permisosLocal[h].data[0][i].nivel2[j].nivel3[l].id +
                      "-" +
                      this.permisosLocal[h].data[0][i].nivel2[j].nivel3[l]
                        .permisos[m].id
                  ] = this.permisosLocal[h].data[0][i].nivel2[j].nivel3[
                    l
                  ].permisos[m].has_permission;
                }
              }
            }
          }
        }
      });
    },

    guardaPermisos(idNivel3) {
      let data = [];
      let permisos = [];

      for (let name in this.permisosGuardar) {
        let info = name.split("-");
        let nivel3 = info[0];
        let id = info[1];

        if (nivel3 == idNivel3) {
          //Valida que solo guarde el nivel deseado.
          if (this.permisosGuardar[name] == true) {
            permisos.push({
              id: id,
              action: "assign",
            });
          } else {
            permisos.push({
              id: id,
              action: "unassign",
            });
          }
        }
      }

      data = {
        role_id: this.role,
        permissions: permisos,
      };
      //alert(JSON.stringify(data));
      this.actSavePermisos(data).then(() => {
        this.$swal({
          icon: "success",
          title: "Los permisos se guardaron correctamente.",
          toast: true,
          position: "bottom-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      });
    },

    pivotTable() {
      return this.$router.push({
        name: "/Admin/SeguridadPivotTable",
      });
    },
  },
};
</script>
